<template>
  <div class="pipeline pd-20">
    <a-page-header class="pd-0 mb-20">
      <div slot="title">
        <h1>
          <img
            src="@/assets/images/dashboard/opportunities/pipeline.png"
            alt="img"
          />
          Funis e Etapas
        </h1>
      </div>
      <template slot="extra">
        <a-button type="primary" ghost @click="openCreateFunnelModal = true">
          <a-icon type="plus" />Novo Funil
        </a-button>
      </template>
    </a-page-header>

    <vueCustomScrollbar class="scroll-area" :settings="settings">
      <div class="funnels-steps">
        <draggable
          v-model="opportunityFunnels.list"
          group="funnels"
          @start="startDrag"
          @end="endDrag"
        >
          <div
            :id="funnel.id"
            :funnel-id="funnel.id"
            class="col"
            v-for="(funnel, index) in opportunityFunnels.list"
            :key="index"
          >
            <FunnelCard
              :loading="opportunityFunnels.loading"
              ref="FunnelCard"
              :funnel="funnel"
              @updateFunnelsList="loadFunnelsList"
            />
          </div>
        </draggable>
      </div>
    </vueCustomScrollbar>

    <a-modal
      width="700px"
      v-model="openCreateFunnelModal"
      title="Criar Funil"
      :footer="false"
      @cancel="openCreateFunnelModal = false"
    >
      <CreateFunnelModal @loadFunnelsList="loadFunnelsList" />
    </a-modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import vueCustomScrollbar from "vue-custom-scrollbar";
//import "../../node_modules/vue-custom-scrollbar/dist/vueScrollbar.css";

import opportunityFunnelsMixins from "@/components/pipeline/mixins/opportunityFunnelsMixins";
import CreateFunnelModal from "@/components/pipeline/modals/CreateFunnelModal.vue";
import FunnelCard from "@/components/pipeline/sections/FunnelCard.vue";

export default {
  name: "FunnelsAndSteps",
  components: { draggable, FunnelCard, CreateFunnelModal, vueCustomScrollbar },
  mixins: [opportunityFunnelsMixins],
  data() {
    return {
      openCreateFunnelModal: false,
      loading: false,
      settings: {
        suppressScrollY: true,
        suppressScrollX: false,
        wheelPropagation: false,
      },
    };
  },
  mounted() {
    this.opportunityFunnels.filters.order = "ascend";
    this.opportunityFunnels.filters.orderBy = "funnel_order";
    this.getOpportunityFunnels();
  },
  methods: {
    updateBlock(block) {
      block;
    },
    loadFunnelsList() {
      this.getOpportunityFunnels();
      this.openCreateFunnelModal = false;
    },
    startDrag(evt) {
      evt;
      this.opportunityFunnels.loading = true;
    },
    endDrag(evt) {
      this.opportunityFunnels.list.forEach((funnel, index) => {
        this.$http
          .post("/opportunity-funnels/update-field", {
            id: funnel.id,
            field: "funnel_order",
            value: index,
          })
          .then(() => {
            this.$http.post("/log/create", {
              user_id: this.$store.state.userData.id,
              module_id: funnel.id,
              module: "opportunity-funnels",
              action: "change-opportunity-funnel-position",
              description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} mudou a posição do funil ID ${funnel.id} de ${funnel.funnel_order} para ${index}`,
            });
          });
      });

      this.$http.post("/opportunity-funnels/update-field", {
        id: evt.clone.id,
        field: "funnel_order",
        value: evt.newIndex,
      });

      setTimeout(() => {
        this.getOpportunityFunnels();
      }, 500);
    },
    onClickAddStep(val) {
      val;
    },
  },
};
</script>

<style lang="sass" scoped>
.funnels-steps
  width: 2800px
  .col
    display: inline-block
    width: 320px
    //background: #f6f6f6
    margin-right: 10px
</style>

<style>
.scroll-area.ps--active-x {
  position: relative;
  margin: auto;
  width: 100%;
  height: 540px;
}
</style>
